import React from "react"

import {LoadingHOC} from "./loading"
import {MessageNotificationHOC} from "./messageNotification"
import {RoleHOC} from "./role"

export const Hoc = ({children}) => {
    return (
        <RoleHOC>
          <LoadingHOC>
            <MessageNotificationHOC>
                {children}
            </MessageNotificationHOC>
          </LoadingHOC>
        </RoleHOC>
    )
}
