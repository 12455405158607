import React, {useEffect, useState} from 'react';
import {getETHUSDTData} from "@api/bybit";
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

const Header = () => {
  const navigate = useNavigate();
  const [ethPrice, setEthPrice] = useState(0);

  useEffect(() => {
    const fetchEthPrice = async () => {
      const result = await getETHUSDTData();

      if (!result) {
        return ;
      }

      const price = parseFloat(result.list[0].lastPrice).toFixed(2);
      setEthPrice(price);
    };

    const interval = setInterval(fetchEthPrice, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.header}>
      <div className={styles.logoAndPrice}>
        <h1 className={styles.logo}>logo</h1>
        <div className={styles.ethPrice}>
          <span className={styles.ethLabel}>ETHUSDT</span>
          <span className={styles.ethValue}>{ethPrice}</span>
        </div>
      </div>
      <button onClick={() => navigate('/new-account')} className={styles.createAccountBtn}>
        Створити акаунт
      </button>
    </div>
  )
}

export default Header;
