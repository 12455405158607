import React from 'react';
import styles from './styles.module.scss';
import EditIcon from "../../../assets/icons/update-icon.svg";
import SettingsIcon from "../../../assets/icons/settings-icon.svg";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const AccountsTable = ({ removeAccount, accounts, handleUpdateField, toggleKeysModal, handleEditAccount, toggleHistoryModal, handleShowHistory }) => (
  <table className={styles.accountsTable}>
    <thead>
    <tr>
      <th>Статус</th>
      <th>Ім'я акаунта</th>
      <th>Ціна Лонг</th>
      <th>Активний</th>
      <th>Ціна Шорт</th>
      <th>
        <span style={{display: 'flex', alignItems: 'center'}}>
          Кількість
          <button onClick={() => handleUpdateField('count')} className={styles.updateBtn}>
            <img src={EditIcon} alt={EditIcon} style={{width: '16px'}}/>
          </button>
        </span>

      </th>
      <th>
        <span style={{display: 'flex', alignItems: 'center'}}>
          О%
          <button onClick={() => handleUpdateField('o_percent')} className={styles.updateBtn}>
            <img src={EditIcon} alt={EditIcon} style={{width: '16px'}}/>
          </button>
        </span>
      </th>
      <th>
        <span style={{display: 'flex', alignItems: 'center'}}>
          Тз сек.
          <button onClick={() => handleUpdateField('tz_sec')} className={styles.updateBtn}>
            <img src={EditIcon} alt={EditIcon} style={{width: '16px'}}/>
          </button>
        </span>
      </th>
      <th>
        <span style={{display: 'flex', alignItems: 'center'}}>
          К%
          <button onClick={() => handleUpdateField('k_percent')} className={styles.updateBtn}>
            <img src={EditIcon} alt={EditIcon} style={{width: '16px'}}/>
          </button>
        </span>
      </th>
      <th>Крайній</th>
      <th>Ціна Відсл-ння</th>
      <th>Історія</th>
      <th>Підк-ння</th>
      <th>Редагувати</th>
      <th>Keys</th>
      <th>Видалення</th>
    </tr>
    </thead>
    <tbody>
    {accounts.map((account) => (
      <tr key={account.id}>
        <td className={`${styles.state} ${styles[account.state.toLowerCase()]}`}>{account.state}</td>
        <td>{account.name}</td>
        <td>{account.long_price}</td>
        <td>
          <span className={account.active ? `${styles.activeText} ${styles.yes}` : `${styles.activeText} ${styles.no}`}>
            {account.active ? 'Так' : 'Ні'}
          </span>
        </td>
        <td>{account.short_price}</td>
        <td>{account.count}</td>
        <td>{account.o_percent}</td>
        <td>{account.tz_sec}</td>
        <td>{account.k_percent}</td>
        <td className={`${styles.state}`}>{account.state}</td>
        <td>{account.trackingPrice}</td>
        <td>
          <button onClick={() => handleShowHistory(account)} className={styles.historyBtn}>
            Історія
          </button>
        </td>
        <td>
          <span
            className={`${styles.connectionStatus} ${account.connection ? styles.connected : styles.disconnected}`}></span>
        </td>
        <td>
          <button onClick={() => handleEditAccount(account)} className={styles.editBtn}>
            <img src={SettingsIcon} alt="Settings" style={{ width: '24px' }} />
          </button>
        </td>
        <td className={`${styles.state}`}>
          <button onClick={() => toggleKeysModal(account)} className={styles.editBtn}>
            <img src={EditIcon} alt="EditIcon" style={{ width: '24px' }} />
          </button>
        </td>
        <td className={`${styles.state}`}>
          <button onClick={() => removeAccount(account)} className={styles.editBtn}>
            <DeleteOutlineIcon color={'error'}/>
          </button>
        </td>
      </tr>
    ))}
    </tbody>
  </table>
);

export default AccountsTable;
