import React from 'react';
import styles from './styles.module.scss';

const EditAccountModal = ({ apiCredentials, onChange, onSave, onClose }) => (
  <div className={styles.modal}>
    <div className={styles.modalContent} style={{ width: '540px' }}>
      <div className={styles.modalHeader}>
        <h2>Налаштування</h2>
        <button onClick={onClose} className={styles.closeBtn}>
          &times;
        </button>
      </div>
      <div className={styles.modalBody}>
        <label htmlFor="apiKey">API-key</label>
        <input
          id="apiKey"
          type="text"
          value={apiCredentials.apiKey}
          onChange={onChange}
          placeholder="xxx"
          className={styles.modalInput}
          style={{ width: '100%' }}
        />
        <label htmlFor="apiSecret">API-secret</label>
        <input
          id="apiSecret"
          type="text"
          value={apiCredentials.apiSecret}
          onChange={onChange}
          placeholder="xxx"
          className={styles.modalInput}
          style={{ width: '100%' }}
        />
      </div>
      <button onClick={onSave} className={styles.modalSaveBtn} style={{ margin: '20px 0' }}>
        Зберегти
      </button>
    </div>
  </div>
);

export default EditAccountModal;
