import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import {edit, deleteAccount, editBatch, get} from "../../api/account";
import { LoadingContext } from "../../hoc/loading";
import { MessageContext } from "../../hoc/messageNotification";
import EditKeysModal from "./EditKeysModal";
import Header from "../Header";
import AccountsTable from "./AccountsTable";
import HistoryModal from "./HistoryModal";
import Modal from "./Modal";

const TablePage = () => {
  const { setLoading } = useContext(LoadingContext);
  const { setErrorMessage } = useContext(MessageContext);
  const navigate = useNavigate();

  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState([]);
  const [modalState, setModalState] = useState({
    isModalOpen: false,
    modalType: '',
    modalValue: '',
  });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [apiCredentials, setApiCredentials] = useState({ apiKey: '', apiSecret: '' });
  const [showHistory, setShowHistory] = useState(false);
  const [selectedAccountHistory, setSelectedAccountHistory] = useState(null);

  const translations = {
    count: 'Кількість',
    o_percent: 'О%',
    tz_sec: 'Тз сек.',
    k_percent: 'К%',
  };

  const fetchAccounts = useCallback(async () => {
    setLoading(true);
    setErrorMessage(null);
    try {
      const obj = await get(1);
      setAccounts(obj);
    } catch (error) {
      setErrorMessage(error.message || 'Failed to fetch accounts.');
    } finally {
      setLoading(false);
    }
  }, [setLoading, setErrorMessage]);

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts]);

  const openModal = (field) => {
    setModalState({ isModalOpen: true, modalType: field, modalValue: '' });
  };

  const closeModal = () => {
    setModalState({ isModalOpen: false, modalType: '', modalValue: '' });
  };

  const saveModalValue = async () => {
    setLoading(true);
    setErrorMessage(null);
    try {
      setAccounts((prevAccounts) =>
        prevAccounts.map((account) => ({
          ...account,
          [modalState.modalType]: modalState.modalValue,
        }))
      );
      await editBatch({
        [modalState.modalType]: Number(modalState.modalValue)
      });

      setAccounts(accounts.map((account) => {
        account[modalState.modalType] = Number(modalState.modalValue);

        return account;
      }));
    } catch (error) {
      setErrorMessage(error.message || 'Failed to fetch accounts.');
    } finally {
      setLoading(false);
    }

    closeModal();
  };

  const handleEditAccount = (account) => {
    navigate(`/edit-account/${account.id}`);
  };

  const saveKeysAccount = async () => {
    console.log('Saving API key:', apiCredentials.apiKey);
    console.log('Saving API secret:', apiCredentials.apiSecret);

    setLoading(true);
    setErrorMessage(null);

    try {
      await edit({
        api_key: apiCredentials.apiKey,
        api_secret: apiCredentials.apiSecret,
      }, account.id);
      fetchAccounts();
    } catch (error) {
      setErrorMessage(error.message || 'Failed to edit account.');
    } finally {
      setLoading(false);
    }

    setIsEditModalOpen(false);
  };

  const toggleHistoryModal = (account = null) => {
    setShowHistory((prevState) => !prevState);
    setSelectedAccountHistory(account);
  };

  const handleShowHistory = (account) => {
    setSelectedAccountHistory(account);
    setShowHistory(true);
  };

  const toggleKeysModal = (account) => {
    setApiCredentials({
      apiKey: account.api_key,
      apiSecret: account.api_secret
    })
    setAccount(account);
    setIsEditModalOpen((prevState) => !prevState);
  }

  const removeAccount = async (account) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      await deleteAccount(account.id);
      fetchAccounts();
    } catch (error) {
      setErrorMessage(error.message || 'Failed to edit account.');
    } finally {
      setLoading(false);
    }

    setIsEditModalOpen(false);
  }

  return (
    <div className={styles.dashboard}>
      <Header />
      <AccountsTable
        accounts={accounts}
        handleUpdateField={openModal}
        handleEditAccount={handleEditAccount}
        toggleHistoryModal={toggleHistoryModal}
        handleShowHistory={handleShowHistory}
        toggleKeysModal={toggleKeysModal}
        removeAccount={removeAccount}
      />
      {modalState.isModalOpen && (
        <Modal
          title={translations[modalState.modalType] || modalState.modalType}
          value={modalState.modalValue}
          onChange={(e) => setModalState((prev) => ({ ...prev, modalValue: e.target.value }))}
          onSave={saveModalValue}
          onClose={closeModal}
        />
      )}
      {isEditModalOpen && (
        <EditKeysModal
          apiCredentials={apiCredentials}
          onChange={(e) => setApiCredentials({ ...apiCredentials, [e.target.id]: e.target.value })}
          onSave={saveKeysAccount}
          onClose={() => setIsEditModalOpen(false)}
        />
      )}
      {showHistory && selectedAccountHistory && (
        <HistoryModal account={selectedAccountHistory} onClose={toggleHistoryModal} />
      )}
    </div>
  );
};

export default TablePage;
