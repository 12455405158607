import React, {useContext} from 'react';
import Button from '../../Button';
import { useNavigate } from "react-router-dom";
import {setToken} from "../../../api/token";
import {LoadingContext} from "../../../hoc/loading";
import {MessageContext} from "../../../hoc/messageNotification";
import {verify} from "../../../api/auth";

const VerifyForm = () => {
  const navigate = useNavigate();
  const {setLoading} = useContext(LoadingContext);
  const {setErrorMessage} = useContext(MessageContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const code = e.target.code.value;

    setLoading(true);
    setErrorMessage(null);

    try {
      const token = await verify(code);
      setToken(token);
      navigate('/table');
    } catch (error) {
      setErrorMessage(error);
    };

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="login-form">
      <label htmlFor="code">Цифровий код</label>
      <input
        id="code"
        type="text"
        placeholder="Enter your verification code"
      />
      <Button type="submit">Перевірити</Button>
    </form>
  );
};

export default VerifyForm;