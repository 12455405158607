const url = 'https://api.bybit.com/v5/market/tickers';

export async function getETHUSDTData() {
  try {
    const response = await fetch(`${url}?category=inverse&symbol=ETHUSDT`);
    const data = await response.json();

    return data.result;
  } catch (error) {
    if (error.response && error.response.status === 429) {
      console.error('Error 429: Too Many Requests. Retrying after a delay...');
    } else {
      console.error('Error fetching data:', error);
    }
  }
}